import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';

import SiteNav from '../shared/siteNav';
import SiteFooter from '../shared/siteFooter';

class Home extends React.Component {
  render() {
    return <div className="App">
    <SiteNav />
      <div className="page-contents bg-white">
      <Carousel fade controls={false}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/dunk.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h2>ELEVATE YOUR COMPANY’S BRAND, IMAGE, PRODUCTS, SERVICES AND PROFITABILITY WITH GUIDANCE FROM INDUSTRY EXPERTS WHO HAVE SUCCESSFULLY GROWN SPORTS BUSINESS FROM ALL ANGLES.</h2>
            <Button as={Link} to={ "/contact-us" }>CONTACT US</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/stadium.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h2>ELEVATE YOUR COMPANY’S BRAND, IMAGE, PRODUCTS, SERVICES AND PROFITABILITY WITH GUIDANCE FROM INDUSTRY EXPERTS WHO HAVE SUCCESSFULLY GROWN SPORTS BUSINESS FROM ALL ANGLES.</h2>
            <Button as={Link} to={ "/contact-us" }>CONTACT US</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/meeting.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h2>ELEVATE YOUR COMPANY’S BRAND, IMAGE, PRODUCTS, SERVICES AND PROFITABILTY WITH GUIDANCE FROM INDUSTRY EXPERTS WHO HAVE SUCCESSFULLY GROWN SPORTS BUSINESS FROM ALL ANGLES.</h2>
            <Button as={Link} to={ "/contact-us" }>CONTACT US</Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="flex-image-container what-we-do">
        <Container>
          <Row>
            <Col className="text-col">
              <div className="spacer90"></div>
              <h3>WHAT WE DO</h3>
              <hr className="small-hr" />
              <h4>Business Experts within the Team Sporting Goods Industry</h4>
              <p>CJSBC has a multitude of industry experience at every level including retail sales and merchandising, team/institutional sales and management, manufacturer and supplier sales/management and business development, and of course - Team Industry Consulting. We’ve played every position on the field and see the business as a whole. Our knowledge in all areas helps achieve success in any part of your business that needs improvement and the all-star team we’ve assembled has the tools you’ll need to grow the business.</p>
              <div className="spacer30">
              </div>
              <h4>We work with you to find and meet your business goals no matter which facet of the business needs improvement.</h4>
              <ul>
                <li>Brand and image building/re-building/relaunching</li>
                <li>Competitive Market Research and analysis</li>
                <li>Product and service evaluation and optimization</li>
                <li>Multi-avenue marketing planning, implementation, organization and execution</li>
                <li>Educated Guidance on Business direction and focus</li>
                <li>Logistics optimization and fine tuning</li>
                <li>Web/digital presence evaluation and improvement</li>
                <li>Grow sales</li>
                <li>Improve merchandising</li>
                <li>Optimize product management and operations</li>
                <li>Revamp supply chain and sourcing</li>
                <li>Upgrade business efficiencies and processes</li>
                <li>Reform business design and development</li>
              </ul>
              <div className="spacer30"></div>
            </Col>
            <Col sm={false} md={true}>
              <div className="flex-image right-half"></div>
            </Col>
          </Row>
        </Container>
        </div>
        <Container>
          <Row>
            <Col className="text-center">
              <div className="spacer90"></div>
              <h3>TRUST THE PROCESS</h3>
              <hr className="small-hr center" />
            </Col>
          </Row>
          <Row className="justify-content-sm-center text-center">
            <Col sm={6} md={4} className="margin-bottom-75">
              <h4>Initial Meeting</h4>
              <p>We’ll gather information on who you are, what about your business you’d like to improve, and explain how we can help you reach your goals.</p>
            </Col>
            <Col sm={6} md={4} className="margin-bottom-75">
              <h4>Planning Outline</h4>
              <p>Based on the initial meeting, we outline our planning, organization, implementation, and execution to achieve success. Quote included so you can accept and begin.</p>
            </Col>
            <Col sm={6} md={4} className="margin-bottom-75">
              <h4>Phase 1 - Research</h4>
              <p>We do our homework and begin with extensive research. After we find the solutions and directions we lay out a step-by-step process to achieve our goals.</p>
            </Col>
            <Col sm={6} md={4} className="margin-bottom-75">
              <h4>Phase 2 - Execute</h4>
              <p>Next we implement and execute the steps laid out in phase 1. We bring plans to reality and see the project through until execution is completed.</p>
            </Col>
            <Col sm={6} md={4} className="margin-bottom-75">
              <h4>Review and Reflect</h4>
              <p>After we’ve completed our grand plans for success, we review results and reflect on the project to ensure satisfaction and results.</p>
            </Col>
          </Row>
        </Container>
      </div>
    <SiteFooter/>
  </div>
  }
}

export default Home;