
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './components/pages/home';
import WhoWeAre from './components/pages/whoWeAre';
import HowWeCanHelp from './components/pages/howWeCanHelp';
import ContactUs from './components/pages/contactUs';

function App() {
  return (
    <Router>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/who-we-are">
            <WhoWeAre />
          </Route>
          <Route path="/how-we-can-help">
            <HowWeCanHelp />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
        </Switch>
    </Router>
  );
}


export default App;
