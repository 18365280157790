import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Collapse, Button } from 'react-bootstrap';

import SiteNav from '../shared/siteNav';
import SiteFooter from '../shared/siteFooter';

class WhoWeAre extends React.Component {
  constructor() {
    super();

    this.state = {
      openChristian: false,
      moreLessChristian: "Read More",
      openErik: false,
      moreLessErik: "Read More",
      openJessie: false,
      moreLessJessie: "Read More",
    }

    this.toggleChristian = this.toggleChristian.bind(this);
    this.toggleErik = this.toggleErik.bind(this);
    this.toggleJessie = this.toggleJessie.bind(this);
  }

  toggleChristian(){
    if (this.state.openChristian) {
      this.setState({openChristian: false, moreLessChristian: "Read More"})
    } else {
      this.setState({openChristian: true, moreLessChristian: "Read Less"})
    }
  }
  toggleErik(){
    if (this.state.openErik) {
      this.setState({openErik: false, moreLessErik: "Read More"})
    } else {
      this.setState({openErik: true, moreLessErik: "Read Less"})
    }
  }
  toggleJessie(){
    if (this.state.openJessie) {
      this.setState({openJessie: false, moreLessJessie: "Read More"})
    } else {
      this.setState({openJessie: true, moreLessJessie: "Read Less"})
    }
  }

  render() {
    return <div className="App">
    <SiteNav />
      <div className="page-contents bg-white">
      <Container>
          <Row>
            <Col className="text-center">
              <div className="spacer90"></div>
              <h3>WHO WE ARE</h3>
              <hr className="small-hr center" />
            </Col>
          </Row>
          <Row className="justify-content-sm-center text-center">
            <Col className="staff-col" lg={4} md={6}>
              <Image roundedCircle className="img-responsive headshot" alt="Christian Stagg" src="/images/headshot-christian.jpg" />
              <h4 className="staff">Christian Stagg</h4>
              <div className="text-left">
                  <p>
                    Here’s a guy who isn’t ashamed of humble beginnings.
                    As a teen working in the local ice rink to make some gas
                    money, Christian found out quickly that the sports and
                    entertainment industry was his calling.
                  </p>
                  <p>
                    During his pursuit of higher education in the Sport
                    Management field, he worked at a local retail and team
                    sporting goods dealer, quite literally from the ground up.
                    Retail sales associate promoted to assistant manager.
                    Internship successfully served as the Manager of Product
                    technology and training. Assistant Manager became
                    Manager and Buyer upon graduation and B.S. degree in
                    Sport Management. Once he felt he had worked as far up
                    as he could there, an opportunity called for an independent
                    sales rep position with an industry leading hockey company.
                  </p>
                  <Collapse in={this.state.openChristian}>
                    <div>
                      <p>
                        Working for the manufacturing company had it’s allure at first
                        but as an independent rep there just wasn’t enough control on
                        the actual product and process. After leaving the ISR position,
                        Christian found his home with a local custom uniform company
                        looking to revive their brand, grow sales and earn market share.
                        After nearly a decade of restoring and rebuilding a great brand,
                        finding out what works in the industry through trial and error,
                        forming long lasting relationships with team dealers across the
                        country, and learning many ins, outs and processes within the
                        manufacturing and supplying scope, he yet again reached his
                        plateau with that company and found another exceptional
                        opportunity.
                      </p>
                      <p>
                        Christian is now the National Sales Manager West for Pearsox,
                        a well-known family owned and operated manufacturer and
                        supplier of stock and custom knit goods and hockey products.
                        Although the continued growth and success of Pearsox is the
                        prime focus throughout the weekdays, he truly enjoys working
                        with others in the team sporting goods industry to enrich the
                        quality of team sports and it’s products and services for athletes
                        across the country and beyond. This well-practiced professional
                        would love to help you and your business succeed and grow!
                      </p>
                    </div>
                  </Collapse>
                  <Button className="btn btn-link" onClick={this.toggleChristian}>{this.state.moreLessChristian}</Button>
              </div>
            </Col>
            <Col className="staff-col" lg={4} md={6}>
              <Image roundedCircle className="img-responsive headshot" alt="Erik Stagg" src="/images/headshot-erik.png" />
              <h4 className="staff">Erik Stagg</h4>
              <div className="text-left">
                <p>Erik is our resident nerd and swiss army knife of internet technology. He has years of experience with web development and application development and is always up for a challenge. A natural born problem solver, he is great at assessing your tech issues and coming up with an efficient solution.</p>
                <p>During his time at Drexel University and Montclair State University, where he finished his B.S. in Computer Science, he learned the core concepts to be successful at what he does and has years of experience with the technologies where those concepts are used. Such technologies he has intimate knowledge of are Html, Javascript/JQuery/Typescript, CSS, SASS, Angular, React, Wordpress, .NET, mySQL, MongoDB, C#, PHP, Java, Android Studio, Photoshop, Windows, Linux, Adobe Premiere Pro/Audition, Ableton Live, and many more.</p>
                  {/* <Collapse in={this.state.openErik}>
                    <div>
                      <p>Quisque vitae consequat est. Vivamus maximus odio sed vestibulum porta. Nunc in quam eget tellus consequat ornare. Aenean nec feugiat massa. Quisque malesuada pellentesque ante a elementum. Praesent massa mauris, suscipit id turpis ut, ullamcorper gravida dolor. Sed ac quam egestas magna tempus vehicula.</p>
                      <p>Sed dapibus nunc nibh, non semper velit tristique sed. Mauris tristique augue mi, quis efficitur turpis porttitor et. Vestibulum sed mauris hendrerit, lobortis neque id, tempor sem. Curabitur sagittis est quis consequat rhoncus. Donec condimentum, odio eu accumsan varius, urna nulla maximus lacus, sit amet volutpat odio lectus eu ex. Nulla facilisi. Mauris non erat felis. Nunc egestas placerat augue, vitae sollicitudin nulla mollis in. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam commodo ut massa at sollicitudin. Nunc tempor enim euismod lectus dapibus rutrum. Curabitur non feugiat nisl. Suspendisse potenti. Vestibulum consequat nulla ut turpis luctus iaculis. In varius ac erat et dictum. Ut imperdiet sed purus vel finibus.</p>
                      <p>Aenean non tincidunt diam. Nullam eget egestas orci, laoreet sodales lectus. In pretium sapien vel bibendum blandit. Etiam pellentesque lacus sed mi imperdiet scelerisque. Quisque eu mauris urna. Nulla facilisi. Sed ultrices iaculis metus nec tristique. Curabitur ut gravida justo. Sed eu felis at purus imperdiet faucibus.</p>
                      <p>Suspendisse quis dui non arcu semper vulputate. Nulla vestibulum lacus vitae neque congue maximus. Ut ac turpis ipsum. Donec fermentum, justo in tincidunt tincidunt, odio mauris viverra tortor, volutpat gravida quam nibh id purus. Phasellus mattis porttitor fermentum. Mauris mollis nibh egestas odio fermentum egestas. Morbi vel blandit nunc, a molestie augue.</p>
                    </div>
                  </Collapse>
                  <Button className="btn btn-link" onClick={this.toggleErik}>{this.state.moreLessErik}</Button> */}
              </div>
            </Col>
            <Col className="staff-col" lg={4} md={6}>
              <Image roundedCircle className="img-responsive headshot" alt="Jesse Baldwin" src="/images/headshot-jesse.png" />
              <h4 className="staff">Jesse Baldwin</h4>
              <div className="text-left">
                <p>Jesse is an experienced and driven sales professional who embraces finding solutions and capitalizing on growth opportunities on a daily basis.</p>
                <p>A graduate of West Chester University and a life-long sporting goods professional, Jesse has acquired an understanding of the industry in both a scholastic and real-world sense. By way of meticulous analysis, strategic planning, efficient execution, and a genuinely enthusiastic attitude, Jesse strives for excellence every step along the way.</p>
                <p>When it comes to Sporting Goods, Jesse has seen it all. Having worked at all levels of the field – from the retail floor of a local Mom and Pop shop to the National Sales Manager of a long-standing industry supplier – Jesse has acquired a wealth of information to share.</p>
                <Collapse in={this.state.openJessie}>
                  <div>
                    <p>Jesse’s journey through the sporting goods world has given him a unique perspective on both team and retail applications and the wherewithal to apply this knowledge to produce tangible solutions to issues spanning the trade.</p>
                  </div>
                </Collapse>
                <Button className="btn btn-link" onClick={this.toggleJessie}>{this.state.moreLessJessie}</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="page-bg-row bg-contact-us">\
        <Container>
          <Row>
            <Col className="text-center">
              <div className="spacer60"></div>
              <h3 className="color-white">CONTACT US</h3>
              <p>Get in touch! We'd love the chance to help you acheive your goals.</p>
              <Button as={Link} to={ "/contact-us" }>CONTACT US</Button>
              <div className="spacer60"></div>
            </Col>
          </Row>
        </Container>
      </div>
    <SiteFooter/>
  </div>
  }
}

export default WhoWeAre;