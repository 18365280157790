import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import firebase from "firebase/app";
import App from './App';
import reportWebVitals from './reportWebVitals';

firebase.initializeApp({
  apiKey: "AIzaSyCBz0KiFGjEpYN2Ru-Bl8Zj6rt4wgWngC8",
  authDomain: "cjsbc-fe05b.firebaseapp.com",
  projectId: "cjsbc-fe05b",
  storageBucket: "cjsbc-fe05b.appspot.com",
  messagingSenderId: "1012954329742",
  appId: "1:1012954329742:web:3c04d832acddc6160a0fdf",
  measurementId: "G-H97NL5E3PR"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
