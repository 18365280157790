import React from 'react';
import { Navbar, Container } from 'react-bootstrap';

class SiteFooter extends React.Component {

  render() {
    let date = new Date();
    let copyrightYear = date.getFullYear();
    const hrStyle = {
      width: 100 + 'px',
    };
    return <div className="footer">
      <Navbar expand="lg" className="bg-white">
        <Container className="justify-content-center">
            <Navbar.Text>
              Copyright © { copyrightYear } CJSBC - All Rights Reserved.
            </Navbar.Text>
            <hr style={ hrStyle } />
        </Container>
      </Navbar>
    </div>
  }
}

export default SiteFooter;