import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

class SiteNav extends React.Component {
  state = { headerLarge: true };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    const { pageYOffset } = window;
    const { headerLarge } = this.state;
    if (pageYOffset >= 500 && headerLarge) {
      this.setState({ headerLarge: false });
    } else if (pageYOffset < 400 && !headerLarge) {
      this.setState({ headerLarge: true });
    }
  };

  render() {
    return <Navbar sticky="top" expand="lg" className="bg-white">
      <Container>
        <Navbar.Brand href="/"><img className={`logo img-responsive ${this.state.headerLarge ? "headerLarge" : ""}`} src="/images/cjsbc-logo-text.svg" alt="CJSBC" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <MenuLink to="/" label="HOME" activeOnlyWhenExact={true} />
            <MenuLink to="/who-we-are" label="WHO WE ARE" />
            {/* <MenuLink to="/how-we-can-help" label="HOW WE CAN HELP" /> */}
            <MenuLink to="/contact-us" label="CONTACT US" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  }
}

export default SiteNav;

function MenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
      <Nav.Item className={match ? "active" : ""}><Nav.Link as={Link} to={ to }>{ label }</Nav.Link></Nav.Item>
  );
}