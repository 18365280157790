import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import SiteNav from '../shared/siteNav';
import SiteFooter from '../shared/siteFooter';

class HowWeCanHelp extends React.Component {
  render() {
    return <div className="App">
    <SiteNav />
      <div className="page-contents bg-gray">
        <Container>
          <Row>
            <Col>
              <p>Help me to help you.</p>
            </Col>
          </Row>
        </Container>
      </div>
    <SiteFooter/>
  </div>
  }
}

export default HowWeCanHelp;