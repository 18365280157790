import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import SiteNav from '../shared/siteNav';
import SiteFooter from '../shared/siteFooter';

import firebase from 'firebase/app';
require ("firebase/functions");
require("firebase/database");

class ContactUs extends React.Component {
  constructor() {
    super();
    
    this.state = {
      contactUsValidated: false,
      contactUsName: "",
      contactUsEmail: "",
      contactUsOrg: "",
      contactUsPhone: "",
      contactUsMessage: "",
      contactUsSubmitMessage: "",
      contactUsSubmitMessageColor: "",
      contactUsSubmitMessageDisplay: "none",
      subscribeValidated: false,
      subscribeEmail: "",
      subscribeSubmitMessage: "",
      subscribeSubmitMessageColor: "",
      subscribeSubmitMessageDisplay: "none"
    };
    
    this.contactUsHandleSubmit = this.contactUsHandleSubmit.bind(this);
    this.subscribeHandleSubmit = this.subscribeHandleSubmit.bind(this);
    this.handleContactUsNameChange = this.handleContactUsNameChange.bind(this);
    this.handleContactUsEmailChange = this.handleContactUsEmailChange.bind(this);
    this.handleContactUsOrgChange = this.handleContactUsOrgChange.bind(this);
    this.handleContactUsPhoneChange = this.handleContactUsPhoneChange.bind(this);
    this.handleContactUsMessageChange = this.handleContactUsMessageChange.bind(this);
    this.handleSubscribeEmailChange = this.handleSubscribeEmailChange.bind(this);
  }

  handleContactUsNameChange(event) { this.setState({contactUsName: event.target.value}); }
  handleContactUsEmailChange(event) { this.setState({contactUsEmail: event.target.value}); }
  handleContactUsOrgChange(event) { this.setState({contactUsOrg: event.target.value}); }
  handleContactUsPhoneChange(event) { this.setState({contactUsPhone: event.target.value}); }
  handleContactUsMessageChange(event) { this.setState({contactUsMessage: event.target.value}); }
  handleSubscribeEmailChange(event) { this.setState({subscribeEmail: event.target.value}); }

  contactUsHandleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({contactUsValidated: true});
    } else {
    
      let formValues = {
        name: this.state.contactUsName,
        email: this.state.contactUsEmail,
        organization: this.state.contactUsOrg,
        phone: this.state.contactUsPhone,
        message: this.state.contactUsMessage,
      }

      const emailSender = firebase.functions().httpsCallable('sendMail');
      const emailCJSBC = firebase.functions().httpsCallable('sendMail');
      emailCJSBC({
        to: "all@cjsbc.com, christian.stagg@cjsbc.com ",
        subject: "New Contact Us Request from Website",
        html: `Request:
                Name: ${formValues.name}<br/>
                Email: ${formValues.email}<br/>
                Organization: ${formValues.organization}<br/>
                Phone: ${formValues.phone}<br/>
                Message: ${formValues.message}`,
      
      })
        .then((result) => {
          emailSender({
            to: formValues.email,
            subject: "Your Request to CJSBC",
            html: `Thank you for your inquiry to CJSBC. Someone will contact you ASAP.<br/>
                    <br/>
                    Your Request:
                    Name: ${formValues.name}<br/>
                    Email: ${formValues.email}<br/>
                    Organization: ${formValues.organization}<br/>
                    Phone: ${formValues.phone}<br/>
                    Message: ${formValues.message}`,
          })
            .then((result) => {
              this.setState({
                contactUsName: "",
                contactUsEmail: "",
                contactUsOrg: "",
                contactUsPhone: "",
                contactUsMessage: "",
                contactUsSubmitMessage: "Your Request has been sent!",
                contactUsSubmitMessageColor: "green",
                contactUsSubmitMessageDisplay: "inline-block",
                contactUsValidated: false
              });
            })
            .catch((error) => {
              console.log("confirmation email error: " + error);
              this.setState({
                contactUsName: "",
                contactUsEmail: "",
                contactUsOrg: "",
                contactUsPhone: "",
                contactUsMessage: "",
                contactUsSubmitMessage: "Your Request has been sent!",
                contactUsSubmitMessageColor: "green",
                contactUsSubmitMessageDisplay: "inline-block",
                contactUsValidated: false
              });
            });
        })
        .catch((error) => {
          console.log("Request email error: " + error);
          this.setState({
            contactUsSubmitMessage: "Your Request failed to send.",
            contactUsSubmitMessageColor: "red",
            contactUsSubmitMessageDisplay: "inline-block",
            contactUsValidated: false
          });
        });
    }
  };

  subscribeHandleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({subscribeValidated: true});
    } else {
      let subscribersRef = firebase.database().ref("subscribers/" + this.state.subscribeEmail.replace(/[.#$[\]]/g, ""));
      subscribersRef.set({
        email: this.state.subscribeEmail
      });
      this.setState({
        subscribeEmail: "",
        subscribeSubmitMessage: "Subscribed Successfully.",
        subscribeSubmitMessageColor: "green",
        subscribeSubmitMessageDisplay: "inline-block",
        subscribeValidated: false
      });
    }
  };

  render() {
    return <div className="App">
    <SiteNav />
      <div className="page-header bg-primary-dark">
        <Container>
          <Row>
            <Col className="text-center">
              <div className="spacer60"></div>
              <h3 className="color-white">CONTACT US</h3>
              <p>Our team loves to talk about how we can solve your problems and achieve your goals.</p>
              <hr className="small-hr center color-primary-light" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="page-contents bg-white">
        <Form noValidate validated={this.state.contactUsValidated} onSubmit={this.contactUsHandleSubmit}>
          <Container>
            <Row>
              <Col>
                <div className="spacer90"></div>
                <h4>Please feel free to contact us for more information.</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" value={this.state.contactUsName} onChange={this.handleContactUsNameChange} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter your name.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="spacer15"></div>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email address *</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={this.state.contactUsEmail} onChange={this.handleContactUsEmailChange} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email.
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <div className="spacer15"></div>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formOrg">
                  <Form.Label>Organization</Form.Label>
                  <Form.Control type="text" placeholder="Enter your organization" value={this.state.contactUsOrg} onChange={this.handleContactUsOrgChange} />
                </Form.Group>
                <div className="spacer15"></div>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="phone" placeholder="Enter your phone number" value={this.state.contactUsPhone} onChange={this.handleContactUsPhoneChange} />
                </Form.Group>
                <div className="spacer15"></div>
              </Col>
              <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={9} value={this.state.contactUsMessage} onChange={this.handleContactUsMessageChange} required />
                <Form.Control.Feedback type="invalid">
                    Please enter your message.
                  </Form.Control.Feedback>
              </Form.Group>
                <div className="spacer15"></div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button><br/>
                <div style={{fontWeight: 'bold', backgroundColor: "white", padding: "3px", display: `${this.state.contactUsSubmitMessageDisplay}`, color: `${this.state.contactUsSubmitMessageColor}`}}>{this.state.contactUsSubmitMessage}</div>
              <div className="spacer60"></div>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
      <div className="page-bg-row bg-subscribe">
        <Form noValidate validated={this.state.subscribeValidated} onSubmit={this.subscribeHandleSubmit}>
        <Container>
          <Row>
            <Col className="text-center">
              <div className="spacer60"></div>
              <h3 className="color-white">SUBSCRIBE</h3>
              <p>Sign up to receive periodic emails about sports industry news and events.</p>
                <div className="spacer15"></div>
            </Col>
          </Row>
          <Row>
              <Col className="flex-container">
                <Form.Group className="mb-3" controlId="formSubscribeEmail">
                  <Form.Control type="email" placeholder="Enter email" value={this.state.subscribeEmail} onChange={this.handleSubscribeEmailChange} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email.
                  </Form.Control.Feedback>
                </Form.Group>
                <div>
                  <Button variant="primary" type="submit" className="mt-3 no-margin">
                    Stay In Touch
                  </Button><br/>
                  <div style={{fontWeight: 'bold', backgroundColor: "white", padding: "3px", display: `${this.state.subscribeSubmitMessageDisplay}`, color: `${this.state.subscribeSubmitMessageColor}`}}>{this.state.subscribeSubmitMessage}</div>
                </div>
              </Col>
          </Row>
          <Row>
            <Col>
                <div className="spacer30"></div>
            </Col>
          </Row>
        </Container>
        </Form>
      </div>
    <SiteFooter/>
  </div>
  }
}

export default ContactUs;